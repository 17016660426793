// ToastProvider.tsx
import React from 'react';
import { useAtom } from 'jotai';
import currentToastsAtom, { CurrentToast } from "atoms/currentToastsAtom";
import Toast from 'components/common/Toast';

const ToastProvider: React.FC = () => {
  const [currentToasts, setCurrentToasts] = useAtom(currentToastsAtom);

  const removeToast = (id: string) => {
    setCurrentToasts((prevToasts) => prevToasts.filter(toast => toast.id !== id));
  };

  return (
    <div className="fixed bottom-4 right-8 flex flex-col-reverse gap-4 z-50">
      {currentToasts.map(({ component, id, type }: CurrentToast) => (
        <Toast
          key={id}
          id={id}
          type={type}
          component={component}
          removeToast={removeToast}
        />
      ))}
    </div>
  );
};

export default ToastProvider;