import darkModeAtom from 'atoms/darkModeAtom';
import { useAtom } from 'jotai';
import { FC, useEffect } from 'react'

export interface ThemeProviderProps {
  children: React.ReactNode
}

const ThemeProvider: FC<ThemeProviderProps> = ({ children }) => {
  const [darkMode] = useAtom(darkModeAtom);

  useEffect(() => {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      document.documentElement.classList.add('dark');
    }
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);

  return <>{children}</>
}

export default ThemeProvider