import currentModalsAtom from "atoms/currentModalsAtom";
import Button from "components/common/Button"
import CheckCircle from "components/common/Icons/CheckCircle";
import DeleteIcon from "components/common/Icons/DeleteIcon";
import useToast from "hooks/useToast";
import { useAtom } from "jotai";
import { modalNames } from "lib/utils/cachingConfig";
import { useState } from "react";

export const Title = () => {
  const [, setCurrentModals] = useAtom(currentModalsAtom);
  const handleNewKey = () => {
    setCurrentModals((currentModals) => [
      ...currentModals,
      {
        id: modalNames.newKey,
        component: NewKeyModalContent,
      },
    ]);
  }

  return (
    <div className="flex flex-row items-center justify-between w-full h-9">
      <p className='w-full text-2xl font-semibold text-text-primary'>API Keys</p>
      <Button
        variant="primary"
        classNames="h-8 min-w-fit text-sm flex items-center justify-center "
        type="button"
        onClick={handleNewKey}
      >
        Generate New Key
      </Button>
    </div>
  )
}

const NewKeyModalContent = () => {
  const [, setCurrentModals] = useAtom(currentModalsAtom);

  const handleClose = () => {
    setCurrentModals((currentModals) => currentModals.filter((modal) => modal.id !== modalNames.newKey));
  }

  const handleGenerateKey = () => {
    // logic to generate key
    handleClose();
  }

  return (
    <div className="flex flex-col items-center justify-center w-[500px] h-fit p-8 rounded-xl bg-bg-secondary gap-4">
      <p className="text-text-primary text-xl font-semibold text-left w-full">New API Key</p>
      <div className="flex flex-col w-full gap-1">
        <p className="text-text-secondary text-sm mt-2">API Key Name</p>
        <div className='w-full flex flex-row items-center border border-border-table gap-0.5 p-2 rounded-[4px] text-text-primary bg-bg-primary'>
          <input
            type="text"
            placeholder="Key Name"
            className="outline-none bg-transparent w-full"
          />
        </div>
      </div>
      <p className="text-text-primary text-sm">
        <span>
          After generating the key make sure to
        </span>
        <span className="font-semibold text-failed">
          {` `}copy the key before it becomes hidden.{` `}
        </span>
        <span>
          It will be viewable immediately after being added to the list, but it won’t be visible again once you navigate away.
        </span>
      </p>
      <div className="flex flex-row gap-4 justify-end w-full">
        <Button
          variant="secondary"
          classNames="h-8 min-w-fit text-sm flex items-center justify-center "
          type="button"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          classNames="h-8 min-w-fit text-sm flex items-center justify-center "
          type="button"
          onClick={handleGenerateKey}
        >
          Generate Key
        </Button>
      </div>
    </div>
  )
}

export const DeleteKeyModalContent = () => {
  const [, setCurrentModals] = useAtom(currentModalsAtom);
  const [inputValue, setInputValue] = useState('');
  const { addToast } = useToast();

  const handleClose = () => {
    setCurrentModals((currentModals) => currentModals.filter((modal) => modal.id !== modalNames.deleteKey));
  }

  const handleDeleteKey = () => {
    if (inputValue !== 'DELETE') return;
    // logic to generate key

    handleClose();
    addToast(SuccessfullyDeletedToast, 'success');
  }

  return (
    <div className="flex flex-col items-center justify-center w-[500px] h-fit p-8 rounded-xl bg-bg-secondary gap-4">
      <div className="flex flex-row items-start justify-between w-full gap-6">
        <div className="rounded-full bg-toast-bg-error p-2">
          <DeleteIcon className="text-toast-icon-error" />
        </div>
        <div className="flex flex-col items-start justify-start w-full gap-1">
          <p className="text-text-primary text-xl font-semibold text-left w-full">Delete API Key</p>
          <div className="flex flex-col w-full gap-1">
            <p className="text-text-secondary text-sm mt-2">
              If you delete the API key, Integrations that use this key won't work. This action cannot be undone.
              <br />
              <br />
              To delete, type DELETE below and confirm the action.</p>
            <div className='w-full flex flex-row items-center border border-border-table gap-0.5 p-2 rounded-[4px] text-text-primary bg-bg-primary'>
              <input
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value.toUpperCase())}
                type="text"
                placeholder="Key Name"
                className="outline-none bg-transparent w-full"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row gap-4 justify-end w-full">
        <Button
          variant="secondary"
          classNames="min-w-fit text-sm flex items-center justify-center"
          type="button"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="error"
          classNames="min-w-fit text-sm flex items-center justify-center"
          type="button"
          isDisabled={inputValue !== 'DELETE'}
          onClick={handleDeleteKey}
        >
          Delete API Key
        </Button>
      </div>
    </div>
  )
}

const SuccessfullyDeletedToast = () => {
  return (
    <div className="flex flex-row items-center justify-between w-full gap-4">
      <div>
        <CheckCircle className="text-toast-icon-success" />
      </div>
      <p className="text-sm">
        Successfully deleted API Key
      </p>
    </div>
  )
}