import Result from "components/common/Result";
import { Column } from "components/common/Table/types";

export const columns: Column[] = [
  {
    key: 'serviceProvider',
    name: 'Service Provider',
    width: 'w-1/4',
    minWidth: 'min-w-[200px]',
    maxWidth: 'max-w-[300px]',
  },
  {
    key: 'participant',
    name: 'Participant',
    width: 'w-1/4',
    minWidth: 'min-w-[200px]',
    maxWidth: 'max-w-[300px]',
  },
  {
    key: 'invoiceNumber',
    name: 'Invoice Number',
    width: 'w-1/4',
    minWidth: 'min-w-[200px]',
    maxWidth: 'max-w-[300px]',
  },
  {
    key: 'result',
    name: 'Result',
    width: 'w-1/4',
    minWidth: 'min-w-[200px]',
    maxWidth: 'max-w-[300px]',
    renderComponent: (props: any) => (
      <Result {...props} />
    ),
  },
  {
    key: 'dateSubmitted',
    name: 'Date Submitted',
    width: 'w-1/4',
    minWidth: 'min-w-[200px]',
    maxWidth: 'max-w-[300px]',
  }
]