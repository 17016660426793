import { useIsAuthenticated } from "@azure/msal-react";
import { getState } from "api";
import { currentAuthenticatedStateAtom } from "atoms/currentAuthenticatedStateAtom"
import { AuthenticatedState } from "atoms/currentAuthenticatedStateAtom/types";
import { useAtom } from "jotai"
import { useCallback, useEffect } from "react";

const useCurrentAuthenticatedState = (): [
  currentAuthenticatedState: AuthenticatedState | null,
  setCurrentAuthenticatedStateAtom: (state: AuthenticatedState) => void,
  fetchCurrentState: () => void
] => {
  const [currentAuthenticatedState, setCurrentAuthenticatedStateAtom] = useAtom(currentAuthenticatedStateAtom);
  const isAuthenticated = useIsAuthenticated();

  const fetchCurrentState = useCallback(async () => {
    const resp = await getState();
    setCurrentAuthenticatedStateAtom(resp);
  }, [setCurrentAuthenticatedStateAtom]);

  useEffect(() => {
    if (isAuthenticated && !currentAuthenticatedState) {
      fetchCurrentState();
    }
  }, [currentAuthenticatedState, setCurrentAuthenticatedStateAtom, isAuthenticated, fetchCurrentState]);

  return [
    currentAuthenticatedState,
    setCurrentAuthenticatedStateAtom,
    fetchCurrentState
  ];
}

export default useCurrentAuthenticatedState;