import { Avatar } from 'components/common/Avatar';
import DigitalDisplay from 'components/common/DigitalDisplay';
import QuestionMarkIcon from 'components/common/Icons/QuestionMarkIcon';
import NavTabs from 'components/common/NavTabs';
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

export interface LayoutProviderProps {
  children: React.ReactNode
}

const LayoutProvider: FC<LayoutProviderProps> = ({ children }) => {
  return (
    <div className='relative flex flex-col items-start justify-start h-screen'>
      <NavBar />
      <main className='flex flex-col items-center justify-center w-full h-[calc(100%_-_64px)] overflow-y-auto'>
        {children}
      </main>
    </div >
  )
}

const NavBar = () => {
  const navigate = useNavigate();
  return (
    <nav className=' px-4 h-16 bg-nav-bg w-full flex items-center justify-between z-50 border-b border-border-table'>
      <div className='flex items-center gap-4 h-full'>
        <img src='/logo.svg' alt='logo' className='cursor-pointer h-10' onClick={() => navigate('/')} />
        <NavTabs />
      </div>
      <div className='flex items-center gap-4 h-full p-2'>
        <DigitalDisplay
          topLeftValue='1'
          topRightValue='2'
          bottomLeftValue='3'
          bottomRightValue='4'
        />
        <QuestionMarkIcon className='text-white cursor-pointer' />
        <Avatar />
      </div>
    </nav >
  )
}

export default LayoutProvider