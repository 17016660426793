import { FC } from 'react'
import ThemeProvider from './ThemeProvider'
import AuthProvider from './AuthProvider'
import { QueryProvider } from './QueryProvider'
import ModalProvider from './ModalProvider'
import ToastProvider from './ToastProvider'

export interface ProvidersProps {
  children: React.ReactNode
}

const Providers: FC<ProvidersProps> = ({ children }) => {
  return (
    <AuthProvider>
      <QueryProvider>
        <ThemeProvider>
          {children}
          <ModalProvider />
          <ToastProvider />
        </ThemeProvider>
      </QueryProvider>
    </AuthProvider>
  )
}

export default Providers