import DatePickerWithRange from 'components/common/DatePickerRange'
import FileUpload from 'components/common/FileUpload'
import Filter from 'components/common/Filter'
import SearchIcon from 'components/common/Icons/SearchIcon'
import { resultFilters } from 'components/common/Result'
import { FC, useState } from 'react'
import { DateRange } from 'react-day-picker'

const Title: FC = () => {
  return (
    <div className='w-full flex flex-col items-start justify-start h-[126px] gap-4'>
      <div className='flex flex-row items-center justify-between text-text-primary w-full'>
        <h1 className="text-2xl font-semibold">Invoices</h1>
        <FileUpload />
      </div>
      <FilterSection />
    </div>
  )
}

const FilterSection: FC = () => {
  const [date, setDate] = useState<DateRange | undefined>(undefined)
  const [selectedFilters, setSelectedFilters] = useState<string[]>([])

  return (
    <div className='w-full flex flex-row items-center justify-between pt-4 border-t border-t-border-table text-text-primary'>
      <div className='flex flex-row gap-4'>
        <DatePickerWithRange
          date={date}
          setDate={setDate}
          placeholderText='Filter by Date'
        />
        <Filter
          label='Result'
          filters={resultFilters}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
        />
      </div>
      <div className='flex flex-row items-center border-b border-b-border-table gap-0.5 p-2'>
        <SearchIcon className='text-text-primary !size-4' />
        <input
          type="text"
          placeholder="Search"
          className="outline-none bg-transparent w-full"
        />
      </div>
    </div>
  )
}

export default Title;