import { FC } from 'react';
import { ButtonProps } from './types';

const Button: FC<ButtonProps> = ({ variant, onClick, children, type, classNames, isDisabled }) => {
  const baseStyles =
    'px-4 py-2 rounded-md font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200 ease-in-out';

  const variantStyles = {
    primary: 'bg-button-primary text-text-button',
    secondary: 'bg-button-secondary text-text-primary',
    error: 'bg-button-error text-text-button',
  };

  const variantHoverStyles = {
    primary: 'hover:bg-button-primary-hover',
    secondary: 'hover:bg-button-secondary-hover',
    error: 'hover:bg-button-error-hover',
  };

  const isDisabledStyles = 'cursor-default opacity-20';

  const className = `${baseStyles} ${variantStyles[variant]} ${classNames} 
  ${isDisabled ? isDisabledStyles : variantHoverStyles[variant]}`;

  return (
    <button onClick={onClick} className={className} type={type} disabled={isDisabled}>
      {children}
    </button>
  );
};

export default Button;