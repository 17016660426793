import CheckCircle from 'components/common/Icons/CheckCircle';
import ExclamationTriangle from 'components/common/Icons/ExclamationTriangle';
import ExclamationCircle from 'components/common/Icons/ExclamationCircle';
import ErrorIcon from 'components/common/Icons/ErrorIcon';
import { FC } from 'react';
import { MarkerProps, TextMarkerProps } from './types';

const InvoiceMarker: FC<MarkerProps> = ({ marker, text }) => {
  switch (marker) {
    case 'complete':
      return <SuccessMarker text={text} />;
    case 'incomplete':
      return <WarningMarker text={text} />;
    case 'failed':
      return <MismatchMarker text={text} />;
    case 'mismatch':
      return <FailureMarker text={text} />;
    default:
      return null;
  }
};

const SuccessMarker: FC<TextMarkerProps> = ({ text }) => {
  return (
    <div className="flex items-center justify-center w-fit h-fit rounded-lg bg-success text-text-primary bg-bg-complete px-4 py-2 gap-2">
      <CheckCircle className="text-text-complete !size-5" />
      <p className="text-xs font-semibold">
        {text}
      </p>
    </div>
  )
}

const WarningMarker: FC<TextMarkerProps> = ({ text }) => {
  return (
    <div className="flex items-center justify-center w-fit h-fit rounded-lg bg-bg-incomplete text-text-primary px-4 py-2 gap-2">
      <ExclamationCircle className="text-text-incomplete !size-5" />
      <p className="text-xs font-semibold">
        {text}
      </p>
    </div>
  )
}

const MismatchMarker: FC<TextMarkerProps> = ({ text }) => {
  return (
    <div className="flex items-center justify-center w-fit h-fit rounded-lg bg-bg-mismatch text-text-primary px-4 py-2 gap-2">
      <ExclamationTriangle className="text-text-mismatch !size-5" />
      <p className="text-xs font-semibold">
        {text}
      </p>
    </div>
  )
}

const FailureMarker: FC<TextMarkerProps> = ({ text }) => {
  return (
    <div className="flex items-center justify-center w-fit h-fit rounded-lg bg-bg-failed text-text-primary px-4 py-2 gap-2">
      <ErrorIcon className="text-text-failed !size-5" />
      <p className="text-xs font-semibold">
        {text}
      </p>
    </div>
  )
}

export default InvoiceMarker;