import { FC } from 'react';
import { useAtom } from 'jotai';
import MoonIcon from '../Icons/MoonIcon';
import SunIcon from '../Icons/SunIcon';
import { cn } from 'lib/utils';
import darkModeAtom from 'atoms/darkModeAtom';

const ThemeToggle: FC = () => {
  const [darkMode, setDarkMode] = useAtom(darkModeAtom);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    localStorage.setItem('darkMode', JSON.stringify(!darkMode));
  };

  return (
    <label className="relative inline-flex cursor-pointer items-center">
      <input id="switch" type="checkbox" className="peer sr-only" onClick={toggleDarkMode} defaultChecked={darkMode} />
      <label htmlFor="switch" className="hidden" />
      <div className={
        cn(
          "flex flex-row justify-between items-center px-0.5 peer h-6 w-11 rounded-full border after:absolute after:left-[2px] after:top-0.5",
          "after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-['']",
          "peer-checked:bg-slate-800 peer-checked:after:translate-x-full"
        )
      }>
        <MoonIcon className='text-text-primary' />
        <SunIcon className='text-text-primary' />
      </div>
    </label>
  );
};

export default ThemeToggle;