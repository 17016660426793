import { cn } from 'lib/utils';
import { FC, HTMLProps } from 'react';

export interface FullscreenLoadingIndicatorProps {
  classNames?: HTMLProps<HTMLElement>["className"]
}

const FullscreenLoadingIndicator: FC<FullscreenLoadingIndicatorProps> = ({ classNames }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-bg-primary bg-opacity-20 z-40">
      <LoadingIndicator classNames={classNames} />
    </div>
  );
};

export const LoadingIndicator: FC<FullscreenLoadingIndicatorProps> = ({ classNames }) => {
  return (
    <div className={cn(
      "w-12 h-12 rounded-full animate-spin border-8 border-solid border-button-primary border-t-transparent",
      classNames
    )} />
  )
}


export default FullscreenLoadingIndicator;