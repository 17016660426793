import { FC, useState } from 'react'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import useInvalidateData from 'hooks/useInvalidateData'
import { cachingInvalidation } from 'lib/utils/cachingConfig'
import FullscreenLoadingIndicator from 'components/common/FullscreenLoadingIndicator'
import Title from './Title'
import Table from 'components/common/Table'
import { columns } from './Columns'
import { useNavigate } from 'react-router-dom'
import { Invoice } from './types'

const InvoicesTable: FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  useInvalidateData(cachingInvalidation.tableName.invoices);
  const navigate = useNavigate();

  const { isPending, isError, data, isFetching, } = useQuery({
    queryKey: ['invoices', currentPage, rowsPerPage],
    queryFn: () => [...placeholderInvoiceData],
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    staleTime: cachingInvalidation.time.invoices,
  });

  const handleRowClick = (row: any) => navigate(`/invoices/${row.id}`);

  if (isPending) return <FullscreenLoadingIndicator />

  return (
    <div className="flex flex-col w-full h-full gap-4 max-w-[1600px]">
      <Title />
      <Table
        columns={columns}
        data={data}
        height="h-[calc(100%_-_142px)]"
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        total={data?.length ?? 0}
        isError={isError}
        isFetching={isFetching}
        onRowClick={handleRowClick}
      />
    </div>
  )
}

export const placeholderInvoiceData: Invoice[] = [
  {
    id: '1',
    serviceProvider: 'Service Provider 1',
    participant: 'Participant 1',
    invoiceNumber: 'Invoice Number 1',
    result: 'complete',
    dateSubmitted: 'Date Submitted 1',
  },
  {
    id: '2',
    serviceProvider: 'Service Provider 2',
    participant: 'Participant 2',
    invoiceNumber: 'Invoice Number 2',
    result: 'incomplete',
    dateSubmitted: 'Date Submitted 2',
  },
  {
    id: '3',
    serviceProvider: 'Service Provider 3',
    participant: 'Participant 3',
    invoiceNumber: 'Invoice Number 3',
    result: 'failed',
    dateSubmitted: 'Date Submitted 3',
  },
  {
    id: '4',
    serviceProvider: 'Service Provider 4',
    participant: 'Participant 4',
    invoiceNumber: 'Invoice Number 4',
    result: 'failed',
    dateSubmitted: 'Date Submitted 4',
  },
  {
    id: '5',
    serviceProvider: 'Service Provider 5',
    participant: 'Participant 5',
    invoiceNumber: 'Invoice Number 5',
    result: 'failed',
    dateSubmitted: 'Date Submitted 5',
  },
  {
    id: '6',
    serviceProvider: 'Service Provider 6',
    participant: 'Participant 6',
    invoiceNumber: 'Invoice Number 6',
    result: 'complete',
    dateSubmitted: 'Date Submitted 6',
  },
  {
    id: '7',
    serviceProvider: 'Service Provider 7',
    participant: 'Participant 7',
    invoiceNumber: 'Invoice Number 7',
    result: 'complete',
    dateSubmitted: 'Date Submitted 7',
  },
  {
    id: '8',
    serviceProvider: 'Service Provider 8',
    participant: 'Participant 8',
    invoiceNumber: 'Invoice Number 8',
    result: 'complete',
    dateSubmitted: 'Date Submitted 8',
  },
  {
    id: '9',
    serviceProvider: 'Service Provider 9',
    participant: 'Participant 9',
    invoiceNumber: 'Invoice Number 9',
    result: 'complete',
    dateSubmitted: 'Date Submitted 9',
  },
  {
    id: '10',
    serviceProvider: 'Service Provider 10',
    participant: 'Participant 10',
    invoiceNumber: 'Invoice Number 10',
    result: 'complete',
    dateSubmitted: 'Date Submitted 10',
  },
]

export default InvoicesTable