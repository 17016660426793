import { atom } from "jotai";

export interface CurrentToast {
  id: string;
  component: React.ComponentType<any>
  type: 'success' | 'error';
}

const currentToastsAtom = atom<CurrentToast[]>([]);

export default currentToastsAtom;