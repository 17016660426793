import { FC } from 'react';
import { MsalAuthenticationTemplate, useIsAuthenticated, } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import useCurrentAuthenticatedState from 'hooks/useCurrentAuthenticatedState';
import FullscreenLoadingIndicator from '../FullscreenLoadingIndicator';

export interface ProtectedRouteProps {
  children: React.ReactNode;
}

// Error component to display when an error occurs
const ErrorComponent = ({ error }: { error: any }) => <p>An Error Occurred: {error}</p>

const LoadingComponent = () => <FullscreenLoadingIndicator />

const ProtectedRoute: FC<ProtectedRouteProps> = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const [currentAuthenticatedState] = useCurrentAuthenticatedState();

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={{
        scopes: [
          'openid',
          process.env.REACT_APP_API_SCOPE as string,
        ],
        prompt: 'login',
      }}
      errorComponent={ErrorComponent}
      loadingComponent={LoadingComponent}
    >
      {(isAuthenticated && currentAuthenticatedState) && children}
    </MsalAuthenticationTemplate>
  );
};

export default ProtectedRoute;
