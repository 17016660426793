import { format } from "date-fns";
import CalendarDateRangeIcon from "../Icons/CalendarDateRangeIcon";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { Button } from "components/ui/button";
import { cn } from "lib/utils";
import { MonthPicker } from "../MonthPicker";
import { FC, HTMLProps, useState } from "react";
import ChevronDownIcon from "../Icons/ChevronDownIcon";

export interface MonthPickerPopoverProps {
  className?: HTMLProps<HTMLElement>["className"]
}

const MonthPickerPopover: FC<MonthPickerPopoverProps> = ({ className }) => {
  const [month, setMonth] = useState<Date | undefined>();
  const [open, setOpen] = useState(false);

  return (
    <div className={cn("grid gap-2", className)}>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            className={cn(
              "w-[200px] justify-between gap-2 text-left font-normal text-text-primary border-border-table bg-bg-secondary",
            )}
          >
            <div className="flex items-center justify-start gap-2">
              <CalendarDateRangeIcon />
              {month ? (
                format(month, "MMMM yyyy")
              ) : (
                <p>Select a month</p>
              )}
            </div>
            <ChevronDownIcon
              className={cn(
                "w-4 h-4",
                "transform transition-transform duration-200",
                open ? "rotate-180" : "rotate-0"
              )}
            />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-4 border-border-table bg-bg-primary " align="start">
          <MonthPicker
            selectedMonth={month}
            onMonthSelect={(selectedMonth) => {
              setMonth(selectedMonth);
            }}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}

export default MonthPickerPopover;