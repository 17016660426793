import { FC } from 'react'
import './styles.css'
import { cn } from 'lib/utils'

export interface DigitalDisplayProps {
  topLeftValue: string
  topRightValue: string
  bottomLeftValue: string
  bottomRightValue: string
}

const DigitalDisplay: FC<DigitalDisplayProps> = ({ topLeftValue, topRightValue, bottomLeftValue, bottomRightValue }) => {
  return (
    <div className={cn(
      'tracking-widest cursor-default text-xs container grid grid-cols-2 grid-rows-2 gap-1 p-1 bg-[#1F2937]',
      'w-[216px] text-right text-text-tertiary font-semibold h-full rounded-sm'
    )}>
      <div className='top-left w-full bg-[#252F3E] px-1 rounded-sm relative'>
        {topLeftValue}
        <div className='absolute top-0 right-1 h-full w-full opacity-10 font-medium'>
          <p>8888888888888</p>
        </div>
      </div>
      <div className='top-right w-full bg-[#252F3E] px-1 rounded-sm relative'>
        {topRightValue}
        <div className='absolute top-0 right-1 h-full w-full opacity-10'>
          <p>8888888888888</p>
        </div>
      </div>
      <div className='bottom-left w-full bg-[#252F3E] px-1 rounded-sm relative'>
        {bottomLeftValue}
        <div className='absolute top-0 right-1 h-full w-full opacity-10'>
          <p>8888888888888</p>
        </div>
      </div>
      <div className='bottom-right w-full bg-[#252F3E] px-1 rounded-sm relative'>
        {bottomRightValue}
        <div className='absolute top-0 right-1 h-full w-full opacity-10'>
          <p>8888888888888</p>
        </div>
      </div>
    </div>
  )
}

export default DigitalDisplay