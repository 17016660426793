import axiosInstance from 'api/config';
import { AuthenticatedState } from 'atoms/currentAuthenticatedStateAtom/types';

export const getState = async (): Promise<AuthenticatedState> => {
  try {
    const response = await axiosInstance.get(`/api/state`);
    return response.data;
  } catch (error) {
    console.error('There was an error getting the current state', error);
    throw error;
  }
};

export const getVersion = async (): Promise<string> => {
  try {
    const response = await axiosInstance.get('/api/version');
    return response.data;
  } catch (error) {
    console.error('There was an error getting the version', error);
    throw error;
  }
};