import { clsx, type ClassValue } from "clsx"
import DocumentIcon from "components/common/Icons/DocumentIcon";
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

const fileIconMap: { [key: string]: string } = {
  avi: '/assets/fileIcons/AVI.svg',
  csv: '/assets/fileIcons/CSV.svg',
  doc: '/assets/fileIcons/DOC.svg',
  docx: '/assets/fileIcons/DOCX.svg',
  gif: '/assets/fileIcons/GIF.svg',
  img: '/assets/fileIcons/IMG.svg',
  jpeg: '/assets/fileIcons/JPEG.svg',
  jpg: '/assets/fileIcons/JPG.svg',
  mkv: '/assets/fileIcons/MKV.svg',
  mp3: '/assets/fileIcons/MP3.svg',
  mp4: '/assets/fileIcons/MP4.svg',
  ogg: '/assets/fileIcons/OGG.svg',
  pdf: '/assets/fileIcons/PDF.svg',
  png: '/assets/fileIcons/PNG.svg',
  ppt: '/assets/fileIcons/PPT.svg',
  pptx: '/assets/fileIcons/PPTX.svg',
  rar: '/assets/fileIcons/RAR.svg',
  txt: '/assets/fileIcons/TXT.svg',
  wav: '/assets/fileIcons/WAV.svg',
  webp: '/assets/fileIcons/WEBP.svg',
  xls: '/assets/fileIcons/XLS.svg',
  xlsx: '/assets/fileIcons/XLSX.svg',
  zip: '/assets/fileIcons/ZIP.svg',
};

export const getFileIcon = (fileType: string) => {
  if (!fileType) return <DocumentIcon className="size-8 text-text-primary" />;
  for (const key in fileIconMap) {
    if (fileType.includes(key)) return <img src={fileIconMap[key]} alt={`${key}-icon`} />;
  }
  return <DocumentIcon className="size-8 text-text-primary" />;
};

export const formatFileSize = (sizeInBytes: number): string => {
  if (sizeInBytes < 1024) {
    return `${sizeInBytes} bytes`;
  } else if (sizeInBytes < 1024 * 1024) {
    const kbSize = (sizeInBytes / 1024).toFixed(1);
    return `${kbSize} KB`;
  } else {
    const mbSize = (sizeInBytes / (1024 * 1024)).toFixed(1);
    return `${mbSize} MB`;
  }
};