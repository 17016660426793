import { atomWithStorage } from 'jotai/utils';

const getInitialDarkMode = () => {
  const storedPreference = localStorage.getItem('darkMode');
  if (storedPreference !== null) {
    return JSON.parse(storedPreference) as boolean;
  }
  return false;
};

const darkModeAtom = atomWithStorage<boolean>('darkMode', getInitialDarkMode());

export default darkModeAtom;