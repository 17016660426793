import Result from 'components/common/Result';
import { FC, useEffect, useRef, useState } from 'react';
import { ViewInvoiceProps } from '../../types';
import Tabs from 'components/common/Tabs';
import { TMarker } from 'components/common/InvoiceMarker/types';
import InvoiceMarker from 'components/common/InvoiceMarker';
import InvoiceDetailsTab from './InvoiceDetailsTab';
import LineItemsTab from './LineItemsTab';

const DetailsSection: FC<ViewInvoiceProps> = ({ currentInvoice }) => {
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateHeight = () => {
      if (containerRef.current) {
        setContainerHeight(containerRef.current.clientHeight);
      }
    };

    // Initial height set
    updateHeight();

    // Using ResizeObserver to observe changes in the container’s height
    const resizeObserver = new ResizeObserver(updateHeight);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    // Cleanup the observer on unmount
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div className="flex flex-col items-center justify-start p-8 gap-8 h-full" >
      <div className="flex flex-col items-center justify-center gap-6 w-full" ref={containerRef}>
        <div className="flex flex-col items-center justify-center gap-2 w-full">
          <div className="flex flex-row items-center justify-start w-full gap-4">
            <p className="text-xl text-text-primary font-semibold">{currentInvoice?.invoiceNumber}</p>
            {currentInvoice?.result ? <Result result={currentInvoice?.result} /> : null}
          </div>
          <p className="text-text-secondary text-sm text-left w-full">
            {currentInvoice?.imageUrl ?? 'No invoice image available'}
          </p>
        </div>
        <div className="flex flex-row flex-wrap items-start justify-start gap-3 w-full">
          {Object.entries(dummyDetails).map(([key, value]) => (
            <InvoiceMarker key={key} marker={value} text={key} />
          ))}
        </div>
      </div>
      <Tabs
        tabs={[
          {
            label: 'Invoice Details',
            content: () => <InvoiceDetailsTab />,
          },
          {
            label: 'Line Items',
            content: () => <LineItemsTab containerHeight={containerHeight} />,
          },
        ]}
      />
    </div>
  );
};

const dummyDetails = {
  invoiceDate: 'complete',
  providerName: 'complete',
  providerABN: 'complete',
  participantName: 'failed',
  participantNumber: 'mismatch',
  productCodes: 'incomplete',
  total: 'complete',
  totalCalculation: 'complete',
  lineItemCalculation: 'complete',
} as Record<string, TMarker>;

export default DetailsSection;