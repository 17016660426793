import { format, startOfYear, addMonths, isSameMonth } from "date-fns";
import { cn } from "lib/utils";
import { buttonVariants } from "components/ui/button";
import ChevronLeftIcon from "components/common/Icons/ChevronLeftIcon";
import ChevronRightIcon from "components/common/Icons/ChevronRightIcon";
import { useMemo, useState } from "react";

export type MonthPickerProps = {
  selectedMonth?: Date;
  onMonthSelect: (month: Date) => void;
  className?: string;
  initialYear?: number;
};

export function MonthPicker({
  selectedMonth,
  onMonthSelect,
  className,
  initialYear = new Date().getFullYear(),
}: MonthPickerProps) {
  const [year, setYear] = useState(initialYear);

  const months = useMemo(() => {
    return Array.from({ length: 12 }, (_, i) =>
      addMonths(startOfYear(new Date(year, 0, 1)), i)
    );
  }, [year]);

  return (
    <div className={cn("space-y-4 text-text-primary bg-bg-primary ", className)}>
      {/* Year Navigation */}
      <div className="flex items-center justify-between">
        <button
          onClick={() => setYear((prevYear) => prevYear - 1)}
          className={cn(
            buttonVariants({ variant: "ghost" }),
            "h-7 w-7 p-0"
          )}
        >
          <ChevronLeftIcon className="h-4 w-4" />
        </button>
        <span className="text-lg font-medium">{year}</span>
        <button
          onClick={() => setYear((prevYear) => prevYear + 1)}
          className={cn(
            buttonVariants({ variant: "ghost" }),
            "h-7 w-7 p-0"
          )}
        >
          <ChevronRightIcon className="h-4 w-4" />
        </button>
      </div>

      {/* Month Grid */}
      <div className="grid grid-cols-3 gap-2">
        {months.map((month) => {
          const isSelected = selectedMonth && isSameMonth(month, selectedMonth);
          return (
            <button
              key={month.toISOString()}
              onClick={() => onMonthSelect(month)}
              className={cn(
                buttonVariants({
                  variant: "default",
                }),
                "w-full text-text-primary bg-bg-secondary hover:bg-datepicker-hover",
                isSelected ? "bg-datepicker-selected" : ""
              )}
            >
              {format(month, "MMM")}
            </button>
          );
        })}
      </div>
    </div>
  );
}