
// Note: Caching control configuration
const short = 60 * 1000; // 1 minute
const medium = 60 * 60 * 1000; // 1 hour
// const long = 60 * 60 * 24 * 1000; // 1 day

export const cachingInvalidation = {
  tableName: {
    invoices: 'invoices',
    analytics: 'analytics',
  },
  time: {
    invoices: short,
    analytics: medium,
  },
};

export const modalNames = {
  newKey: 'new-key',
  deleteKey: 'delete-key',
};