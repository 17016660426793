// hooks/useToast.ts
import { useSetAtom } from 'jotai';
import currentToastsAtom, { CurrentToast } from "atoms/currentToastsAtom";

const useToast = () => {
  const setCurrentToasts = useSetAtom(currentToastsAtom);

  const addToast = (component: React.ComponentType<any>, type: 'success' | 'error') => {
    const id = new Date().getTime().toString();
    const newToast: CurrentToast = { id, component, type };
    setCurrentToasts((prevToasts) => [...prevToasts, newToast]);
  };

  return { addToast };
};

export default useToast;