import { FC } from 'react';
import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import { LogoutButtonProps } from './types';
import Button from '../Button';

// A button that logs the user in or out, depending on their current state
const LogoutButton: FC<LogoutButtonProps> = ({ classNames, children }) => {
  const { instance: msalInstance } = useMsal();

  function handleLogout() {
    msalInstance.logoutRedirect({
      postLogoutRedirectUri: window.location.origin,
    }).catch(error => {
      console.error('Logout failed:', error);
    });
    localStorage.removeItem('user');
  }

  return (
    <AuthenticatedTemplate>
      <Button
        type='button'
        variant='primary'
        onClick={handleLogout}
        aria-label='Logout'
        data-testid='authenticationLogoutButton'
        classNames={classNames}
      >
        {children}
      </Button>
    </AuthenticatedTemplate>
  );
};

export default LogoutButton;