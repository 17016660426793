import { keepPreviousData, useQuery } from "@tanstack/react-query"
import FullscreenLoadingIndicator from "components/common/FullscreenLoadingIndicator"
import Table from "components/common/Table"
import useInvalidateData from "hooks/useInvalidateData"
import { cachingInvalidation } from "lib/utils/cachingConfig"
import { useState } from "react"
import { Title } from "./Title"
import { columns } from "./Columns"

const KeysTable = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  useInvalidateData(cachingInvalidation.tableName.invoices);

  const { isPending, isError, data, isFetching, } = useQuery({
    queryKey: ['keys', currentPage, rowsPerPage],
    queryFn: () => [...placeholderData],
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    staleTime: cachingInvalidation.time.invoices,
  });

  if (isPending) return <FullscreenLoadingIndicator />

  return (
    <div className="flex flex-col w-full h-full gap-4 max-w-[1600px]">
      <Title />
      <Table
        columns={columns}
        data={data}
        height="h-[calc(100%_-_48px)]"
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        total={data?.length ?? 0}
        isError={isError}
        isFetching={isFetching}
      />
    </div>
  )
}

const placeholderData = [
  {
    name: 'Key 1',
    api_key: '1234567890',
    created: '2021-09-01',
  },
  {
    name: 'Key 2',
    api_key: '1234567890',
    created: '2021-09-01',
  },
  {
    name: 'Key 3',
    api_key: '1234567890',
    created: '2021-09-01',
  },
  {
    name: 'Key 4',
    api_key: '1234567890',
    created: '2021-09-01',
  },
  {
    name: 'Key 5',
    api_key: '1234567890',
    created: '2021-09-01',
  },
  {
    name: 'Key 6',
    api_key: '1234567890',
    created: '2021-09-01',
  },
  {
    name: 'Key 7',
    api_key: '1234567890',
    created: '2021-09-01',
  },
  {
    name: 'Key 8',
    api_key: '1234567890',
    created: '2021-09-01',
  },
  {
    name: 'Key 9',
    api_key: '1234567890',
    created: '2021-09-01',
  },
  {
    name: 'Key 10',
    api_key: '1234567890',
    created: '2021-09-01',
  },
]

export default KeysTable