import DeleteIcon from "components/common/Icons/DeleteIcon"
import { FC } from "react"
import { DeleteActionProps } from "../types";
import CopyToClipboard from "components/common/CopyToClipboard";
import { useAtom } from "jotai";
import currentModalsAtom from "atoms/currentModalsAtom";
import { DeleteKeyModalContent } from "../Title";
import { modalNames } from "lib/utils/cachingConfig";

export const columns = [
  {
    key: 'name',
    name: 'Name',
    width: 'w-1/4',
    minWidth: 'min-w-[200px]',
    maxWidth: 'max-w-[300px]',
  },
  {
    key: 'api_key',
    name: 'API Key',
    width: 'w-1/4',
    minWidth: 'min-w-[200px]',
    maxWidth: 'max-w-[300px]',
    renderComponent: (props: any) => (
      <ApiKey {...props} />
    ),
  },
  {
    key: 'created',
    name: 'Created',
    width: 'w-full',
    minWidth: 'min-w-[200px]',
    maxWidth: 'max-w-full',
  },
  {
    key: 'delete',
    name: 'Action',
    width: 'w-[100px]',
    minWidth: 'min-w-[100px]',
    maxWidth: 'max-w-[300px]',
    renderComponent: (props: any) => (
      <DeleteAction {...props} />
    ),
  }
]

const ApiKey = ({ row }: any) => {
  const canCopy = true;

  return (
    <div className="flex items-center w-full justify-start gap-2">
      <div className="truncate">{row.api_key}</div>
      {canCopy && <CopyToClipboard text={row.api_key} />}
    </div>
  );
};

const DeleteAction: FC<DeleteActionProps> = ({ row }) => {
  const [, setCurrentModals] = useAtom(currentModalsAtom);

  const handleDelete = () => {
    setCurrentModals((currentModals) => [
      ...currentModals,
      {
        id: modalNames.deleteKey,
        component: DeleteKeyModalContent,
      },
    ]);
  }

  return (
    <div className="flex justify-center cursor-pointer text-icon-main" onClick={handleDelete}>
      <DeleteIcon />
    </div>
  );
};