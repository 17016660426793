import { FC, useEffect, useRef, useState } from 'react';
import { AvatarProps } from './types';
import AvatarIcon from '../Icons/AvatarIcon';
import ThemeToggle from '../ThemeToggle';
import LogoutIcon from '../Icons/LogoutIcon';
import LogoutButton from '../LogoutButton';
import { cn } from 'lib/utils';
import { useAtom } from 'jotai';
import { currentAuthenticatedStateAtom } from 'atoms/currentAuthenticatedStateAtom';

const Avatar: FC<AvatarProps> = ({ className }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const avatarRef = useRef<HTMLDivElement>(null);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (avatarRef.current && !avatarRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={avatarRef} className={`relative inline-block ${className}`}>
      <div
        className='flex h-10 w-10 shrink-0 overflow-hidden rounded-full cursor-pointer'
        onClick={() => setIsDropdownOpen((prev) => !prev)}
      >
        <AvatarDetail />
      </div>
      {isDropdownOpen && <AvatarDropdown />}
    </div>
  );
};

const AvatarDetail: FC = () => {
  const [currentAuthenticatedState] = useAtom(currentAuthenticatedStateAtom);;
  return (
    <div className={`flex h-full w-full items-center justify-center rounded-full bg-muted bg-avatar-bg`}>
      {currentAuthenticatedState?.authenticatedUser?.profilePicture ? (
        <img src="https://github.com/shadcn.png" alt='User Profile' className={`aspect-square h-full w-full`} />
      ) : (
        <AvatarIcon className='text-avatar-icon' />
      )}
    </div>
  )
}

const AvatarDropdown: FC = () => {
  const [currentAuthenticatedState] = useAtom(currentAuthenticatedStateAtom);

  return (
    <div className={cn(
      "absolute flex flex-col items-start justify-between top-full p-2 right-0 mt-2 bg-bg-primary transition-[background-color] ease-in-out duration-300",
      "rounded-lg shadow-lg w-[320px] h-[304px] z-[10000] border border-border-main"
    )}>
      <div className="flex flex-row items-center justify-between w-full p-4 gap-2">
        <div className='flex h-10 w-10 shrink-0 overflow-hidden rounded-full cursor-pointer'>
          <AvatarDetail />
        </div>
      </div>
      <div className='flex flex-col gap-0.5 w-full p-4'>
        <p className='text-text-primary font-semibold text-base'>{currentAuthenticatedState?.authenticatedUser?.fullName}</p>
        <p className='text-text-secondary font-normal text-base'>{currentAuthenticatedState?.authenticatedUser?.email}</p>
        <p className='text-text-tertiary font-semibold text-sm'>{currentAuthenticatedState?.tenant.name}</p>
      </div>
      <div className='flex flex-row items-center justify-between border-border-secondary border-y-[1px] w-full p-4'>
        <p className='text-text-primary font-normal text-base'>Theme</p>
        <ThemeToggle />
      </div>
      <LogoutButton classNames='flex flex-row bg-transparent text-text-primary gap-2 hover:bg-transparent'>
        <LogoutIcon /> Logout
      </LogoutButton>
    </div>
  );
};

export { Avatar, AvatarDetail }