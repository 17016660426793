import { useState, useEffect, useRef, useMemo } from 'react';
import { cn, formatFileSize } from 'lib/utils';
import ChevronDownIcon from '../Icons/ChevronDownIcon';
import DocumentArrowDownIcon from '../Icons/DocumentArrowDownIcon';
import FileDropdown from './FileDropdown';

const FileUpload = () => {
  const [isDragging, setIsDragging] = useState(false);
  const [files, setFiles] = useState<any>([]);
  const [fileDropdownIsOpen, setFileDropdownIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [totalFileSize, setTotalFileSize] = useState('');
  const supportedFileTypes = useMemo(() => ['.pdf', '.png', '.jpeg', '.jpg'], []);
  const fileSizeLimit = 10 * 1024 * 1024; // 10MB
  const [errors, setErrors] = useState<string[]>([]);

  const handleClick = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = supportedFileTypes.join(',');
    input.multiple = true;
    input.onchange = (e: any) => {
      setErrors([]);
      const filesArray = Array.from(e.target.files);
      filesArray.forEach((file: any) => {
        if (file.size > fileSizeLimit) {
          setErrors((prevErrors: any) => [...prevErrors, 'size']);
        } else {
          setFiles((prevFiles: any) => [...prevFiles, file]);
        }
        setFileDropdownIsOpen(true);
      });
    };
    input.click();
  };

  useEffect(() => {
    const handleDragEnter = (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(true);
    };

    const handleDragOver = (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(true);
    };

    const handleDragLeave = (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      // Only set isDragging to false when the mouse is outside the window
      if (e.screenX === 0 && e.screenY === 0) {
        setIsDragging(false);
      }
    };

    const handleDrop = (e: any) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
      setErrors([]);
      if (e.dataTransfer?.files && e.dataTransfer.files.length > 0) {
        const droppedFiles = Array.from(e.dataTransfer.files);

        droppedFiles.forEach((file: any) => {
          const fileExtension = `.${file.name.split('.').pop()?.toLowerCase()}`;
          if (file.size > fileSizeLimit) {
            setErrors((prevErrors: any) => [...prevErrors, `size`]);
          } else if (!supportedFileTypes.includes(fileExtension)) {
            setErrors((prevErrors: any) => [...prevErrors, `type`]);
          } else {
            setFiles((prevFiles: any) => [...prevFiles, file]);
          }
          setFileDropdownIsOpen(true);
        });
      };
    }

    window.addEventListener('dragenter', handleDragEnter);
    window.addEventListener('dragover', handleDragOver);
    window.addEventListener('dragleave', handleDragLeave);
    window.addEventListener('drop', handleDrop);

    return () => {
      window.removeEventListener('dragenter', handleDragEnter);
      window.removeEventListener('dragover', handleDragOver);
      window.removeEventListener('dragleave', handleDragLeave);
      window.removeEventListener('drop', handleDrop);
    };
  }, [fileSizeLimit, supportedFileTypes]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setFileDropdownIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef])

  useEffect(() => {
    let totalSize = 0;
    files.forEach((file: any) => totalSize += file.size);
    setTotalFileSize(formatFileSize(totalSize));
  }, [files]);

  const Overlay = () => (
    <div className='fixed p-8 top-0 left-0 w-full h-full bg-bg-overlay z-50 flex items-center justify-center backdrop-blur-sm'>
      <div className='flex flex-col items-center justify-center gap-4 border-4 border-border-overlay border-dashed w-full h-full rounded-xl'>
        <DocumentArrowDownIcon className='size-24 text-text-tertiary' />
        <p className='text-text-primary text-5xl font-medium text-center'>Drop files here</p>
        <p className='text-text-secondary text-xl font-semibold text-center'>You have 10 free uploads left</p>
      </div>
    </div>
  );

  return (
    <div className='relative' ref={dropdownRef}>
      {isDragging && <Overlay />}
      <div className="flex flex-row gap-4 items-center">
        <div className="flex flex-col text-left items-end justify-center">
          <p className='text-base font-semibold'>Upload Invoice</p>
          <p className='text-sm text-text-tertiary'>(10/10 free uploads)</p>
        </div>
        {files.length > 0 ? (
          <div
            onClick={() => setFileDropdownIsOpen(!fileDropdownIsOpen)}
            className={
              cn(
                "flex flex-row items-center  transition-[background-color] ease-in-out duration-300 gap-2 w-[430px] rounded-[4px]",
                "border-[#54C45E] py-2 px-3 h-[52px] cursor-pointer bg-bg-tertiary border-2 border-border-tertiary"
              )}
          >
            <DocumentArrowDownIcon className='text-text-tertiary' />
            <div className='flex flex-row justify-between w-full items-center'>
              <span className='flex flex-row gap-1 items-center text-sm font-medium'>
                <p className='text-text-tertiary'>{files.length} file{files.length > 1 ? 's' : ''} pending upload</p>
                <p className='text-text-secondary text-xs'>({totalFileSize})</p>
              </span>
              <ChevronDownIcon className={cn(
                'text-text-primary h-4 w-4 transition-transform duration-200 ease-in-out',
                fileDropdownIsOpen ? 'rotate-180' : 'rotate-0'
              )}
              />
            </div>
          </div>
        ) : (
          <div
            onClick={handleClick}
            className={
              cn(
                "flex flex-row items-center  transition-[background-color] ease-in-out duration-300 gap-2 w-[430px] rounded-[4px]",
                "border-[#54C45E] py-2 px-3 h-[52px] cursor-pointer bg-bg-secondary border border-dashed"
              )}
          >
            <DocumentArrowDownIcon className='text-text-tertiary' />
            <div className='flex flex-col'>
              <span className='flex flex-row gap-1 items-center text-sm font-medium'>
                <p className='text-text-tertiary'>Click to upload</p>
                <p>or drag and drop</p>
              </span>
              <p className='text-text-secondary text-xs'>
                Supported files: {supportedFileTypes
                  .map(type => type.replace('.', '').toUpperCase())
                  .join(', ')}
              </p>
            </div>
          </div>
        )}
      </div>
      {fileDropdownIsOpen && (
        <FileDropdown
          files={files}
          setFiles={setFiles}
          setFileDropdownIsOpen={setFileDropdownIsOpen}
          errors={errors}
          fileSizeLimit={fileSizeLimit}
        />
      )}
    </div>
  );
};

export default FileUpload;