import { FC } from "react";
import CheckCircle from "../Icons/CheckCircle";
import ExclamationCircle from "../Icons/ExclamationCircle";
import ErrorIcon from "../Icons/ErrorIcon";

export type TResult = 'complete' | 'incomplete' | 'failed';

export interface ResultProps {
  result: 'complete' | 'incomplete' | 'failed';
}

export const results: TResult[] = ['complete', 'incomplete', 'failed'];

const Result: FC<ResultProps> = ({ result }) => {
  return (
    <>
      {result === 'complete' ? (
        <div className="flex items-center gap-0.5 border border-border-complete bg-bg-complete rounded-[100px] px-1 py-0.5">
          <CheckCircle className='w-4 h-4 text-icon-complete' />
          <p className='text-xs font-semibold text-complete uppercase pr-0.5'>Complete</p>
        </div>
      ) : result === 'incomplete' ? (
        <div className="flex items-center gap-0.5 border border-border-incomplete bg-bg-incomplete rounded-[100px] px-1 py-0.5">
          <ExclamationCircle className='w-4 h-4 text-icon-incomplete' />
          <p className='text-xs font-semibold text-incomplete uppercase pr-0.5'>Incomplete</p>
        </div>
      ) : result === 'failed' ? (
        <div className="flex items-center gap-0.5 border border-border-failed bg-bg-failed rounded-[100px] px-1 py-0.5">
          <ErrorIcon className='w-4 h-4 text-icon-failed' />
          <p className='text-xs font-semibold text-failed uppercase pr-0.5'>Failed</p>
        </div>
      ) : null}
    </>
  )
}

export const resultFilters = results.map((result) => ({
  label: <Result result={result} />,
  value: result
}))

export default Result