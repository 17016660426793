import { FC, useRef, useEffect, useState } from 'react';
import { Column, TableProps } from './types';
import PaginationFooter from 'components/common/Table/PaginationFooter';
import './styles.css';
import { cn } from 'lib/utils';
import { LoadingIndicator } from '../FullscreenLoadingIndicator';

const Table: FC<TableProps> = ({
  columns,
  data,
  height,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  setRowsPerPage,
  total,
  isError,
  isFetching,
  onRowClick,
}) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const [scrollbarWidth, setScrollbarWidth] = useState<number | null>(null);

  // Syncs the horizontal scroll of the header and body of the table
  useEffect(() => {
    const handleBodyScroll = () => {
      if (headerRef.current && bodyRef.current) {
        headerRef.current.scrollLeft = bodyRef.current.scrollLeft;
      }
    };
    const bodyElement = bodyRef.current;

    if (bodyElement) bodyElement.addEventListener('scroll', handleBodyScroll);
    return () => {
      if (bodyElement) bodyElement.removeEventListener('scroll', handleBodyScroll);
    };
  }, []);

  // Calculate scrollbar width and updates header padding to ensure the width of the header and body match
  useEffect(() => {
    const getScrollbarWidth = () => {
      if (bodyRef.current) {
        const scrollbarWidth = bodyRef.current.offsetWidth - bodyRef.current.clientWidth;
        setScrollbarWidth(scrollbarWidth);
      }
    };

    getScrollbarWidth();

    // Recalculate on window resize
    window.addEventListener('resize', getScrollbarWidth);
    return () => {
      window.removeEventListener('resize', getScrollbarWidth);
    };
  }, []);

  return (
    <div className={cn(
      "relative flex flex-col text-text-primary border border-border-table w-full",
      height
    )}>
      <div
        ref={headerRef}
        className="column-header h-12 min-h-12 w-full border-b border-border-table flex flex-row overflow-hidden bg-bg-secondary transition-[background-color] ease-in-out duration-300"
        style={{ paddingRight: `${scrollbarWidth}px` }}
      >
        {scrollbarWidth !== null && columns.map((column: Column) => {
          return (
            <div
              key={column.key}
              className={`px-2 flex flex-row items-center justify-start h-full ${column.width} ${column.minWidth} ${column.maxWidth}`}
            >
              {column.name}
            </div>
          );
        })}
      </div>
      <div
        ref={bodyRef}
        className="w-full min-h-[calc(100%_-_96px)] h-fit overflow-auto divide-y divide-border-table"
      >
        {data ? (
          data.map((row, index) => (
            <div
              key={index}
              className="flex flex-row h-12 min-h-12 min-w-fit w-full"
              onClick={() => onRowClick && onRowClick(row)}
            >
              {columns.map((column: Column) => {
                return (
                  <div
                    key={column.key}
                    className={`flex flex-row items-center justify-start px-2 h-full ${column.width} ${column.minWidth} ${column.maxWidth}`}
                  >
                    {column.renderComponent
                      ? column.renderComponent({ row })
                      : row[column.key as keyof typeof row]}
                  </div>
                );
              })}
            </div>
          ))
        ) : isError ? (
          <div className="flex flex-row items-center justify-center h-full w-full">
            <p>Error fetching data</p>
          </div>
        ) : isFetching ? (
          <div className="flex flex-row items-center justify-center h-full w-full">
            <LoadingIndicator />
          </div>
        ) : (
          <div className="flex flex-row items-center justify-center h-full">
            <p>No data available</p>
          </div>
        )}
      </div>
      <PaginationFooter
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        total={total}
      />
    </div>
  );
};

export default Table;