import AddIcon from 'components/common/Icons/AddIcon';
import CloseIcon from 'components/common/Icons/CloseIcon';
import MinusIcon from 'components/common/Icons/MinusIcon';
import { Invoice } from 'components/features/InvoicesTable/types';
import { cn } from 'lib/utils';
import { FC, useEffect, useRef, useState } from 'react';
import { ViewInvoiceProps } from '../../types';

const ViewInvoiceSection: FC<ViewInvoiceProps> = ({ currentInvoice }) => {
  const [scale, setScale] = useState(1);
  const outerContainerRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isPanning, setIsPanning] = useState(false);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [scrollOffset, setScrollOffset] = useState({ x: 0, y: 0 });

  currentInvoice = {
    ...currentInvoice,
    imageUrl: [
      '/assets/917105d879a281eec331719adcd53f1e.png',
      '/assets/917105d879a281eec331719adcd53f1e.png',
      '/assets/917105d879a281eec331719adcd53f1e.png',
    ]
  } as Invoice;

  // Handlers for zooming
  const handleZoomIn = () => setScale((prevScale) => Math.min(prevScale + 0.1, 3)); // Max zoom at 3x
  const handleZoomOut = () => setScale((prevScale) => Math.max(prevScale - 0.1, 0.5)); // Min zoom at 0.5x
  const handleResetZoom = () => setScale(1);

  // Mouse down to start panning
  const handleMouseDown = (e: React.MouseEvent) => {
    if (outerContainerRef.current) {
      setIsPanning(true);
      setStartPos({ x: e.clientX, y: e.clientY });
      setScrollOffset({
        x: outerContainerRef.current.scrollLeft,
        y: outerContainerRef.current.scrollTop,
      });
    }
  };

  // Mouse up to stop panning
  const handleMouseUp = () => {
    setIsPanning(false);
  };

  // Mouse move to update scroll position if panning
  const handleMouseMove = (e: React.MouseEvent) => {
    if (isPanning && outerContainerRef.current) {
      const deltaX = e.clientX - startPos.x;
      const deltaY = e.clientY - startPos.y;
      outerContainerRef.current.scrollLeft = scrollOffset.x - deltaX;
      outerContainerRef.current.scrollTop = scrollOffset.y - deltaY;
    }
  };

  useEffect(() => {
    const handleZoom = (e: WheelEvent) => {
      if (e.ctrlKey) {
        e.preventDefault();
        e.deltaY > 0 ? handleZoomOut() : handleZoomIn();
      }
    };

    window.addEventListener('wheel', handleZoom, { passive: false });
    window.addEventListener('mouseup', handleMouseUp); // Stop panning on mouse up

    return () => {
      window.removeEventListener('wheel', handleZoom);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);


  return (
    <div className='flex flex-col items-start justify-start gap-4 relative w-full select-none'>
      <div
        ref={outerContainerRef}
        onMouseMove={handleMouseMove}
        onMouseDown={handleMouseDown}
        className={cn(
          "flex flex-col items-start justify-start gap-4 overflow-auto relative h-full w-full p-8",
          isPanning ? 'cursor-grabbing' : 'cursor-grab'
        )}
      >
        <div className='w-full'>
          <p className="text-xl text-text-primary font-semibold text-left w-full">
            {currentInvoice?.invoiceNumber}
          </p>
        </div>
        {currentInvoice?.imageUrl ?
          <div
            ref={containerRef}
            className="test w-full flex flex-col items-start justify-start gap-4 transition-transform duration-200 origin-top-left min-h-fit"
            style={{
              height: `${100 * scale}%`,
              width: `${100 * scale}%`,
            }}
          >
            {currentInvoice.imageUrl.map((url, index) => (
              <img
                key={index}
                src={url}
                alt="Invoice"
                className='w-full h-auto'
                draggable={false}
              />
            ))}
          </div> :
          <p className="text-text-secondary text-sm text-left w-full p-8 pt-0">
            N/A
          </p>
        }
      </div>
      <div className="absolute bottom-4 right-4 flex flex-col gap-2">
        {scale !== 1 && (
          <button
            onClick={handleResetZoom}
            className={cn(
              "w-9 h-9 p-2 bg-bg-invoice-button rounded-full text-text-primary flex items-center justify-center text-xl",
              "shadow-[0px_4px_12px_rgba(31,41,55,0.10),0px_8px_16px_-8px_rgba(31,41,55,0.15)]"
            )}
          >
            <CloseIcon />
          </button>
        )}
        <button
          onClick={handleZoomIn}
          className={cn(
            "w-9 h-9 p-2 bg-bg-invoice-button rounded-full text-text-primary flex items-center justify-center text-xl",
            "shadow-[0px_4px_12px_rgba(31,41,55,0.10),0px_8px_16px_-8px_rgba(31,41,55,0.15)]"
          )}
        >
          <AddIcon />
        </button>
        <button
          onClick={handleZoomOut}
          className={cn(
            "w-9 h-9 p-2 bg-bg-invoice-button rounded-full text-text-primary flex items-center justify-center text-xl",
            "shadow-[0px_4px_12px_rgba(31,41,55,0.10),0px_8px_16px_-8px_rgba(31,41,55,0.15)]"
          )}
        >
          <MinusIcon />
        </button>
      </div>
    </div >
  )
};

export default ViewInvoiceSection;