import { cn } from "lib/utils"
import { FC } from "react"
import { PaginationFooterProps } from "../../../features/InvoicesTable/types";
import ChevronLeftIcon from "components/common/Icons/ChevronLeftIcon";
import ChevronRightIcon from "components/common/Icons/ChevronRightIcon";

const PaginationFooter: FC<PaginationFooterProps> = ({
  currentPage,
  setCurrentPage,
  rowsPerPage,
  setRowsPerPage,
  total
}) => {
  const totalPages = Math.ceil(total / rowsPerPage);

  const indexOfLastInvoice = currentPage * rowsPerPage;
  const indexOfFirstInvoice = indexOfLastInvoice - rowsPerPage;


  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setRowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  return (
    <div className={cn(
      "h-12 min-h-12 w-full border-t border-border-table",
      "px-4 w-full flex items-center justify-between"
    )}>
      {/* Left Side */}
      <div className="flex items-center space-x-2">
        <p className='text-xs text-text-secondary'>Rows per page:</p>
        <select
          className="border border-border-table rounded px-2 py-1 bg-table-number-selected cursor-pointer"
          value={rowsPerPage}
          onChange={handleRowsPerPageChange}
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={25}>25</option>
        </select>
        <p className='text-xs'>
          Showing {indexOfFirstInvoice + 1}-{Math.min(indexOfLastInvoice, total)} of {total} records
        </p>
      </div>

      {/* Right Side */}
      <div className="flex items-center space-x-1">
        <button
          className={`px-2 py-1 ${currentPage === 1 ? 'opacity-40' : 'cursor-pointer'}`}
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          <ChevronLeftIcon />
        </button>
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
          <button
            key={page}
            disabled={currentPage === page}
            className={`px-2 py-1 rounded-sm ${currentPage === page ? 'bg-table-number-selected text-text cursor-default' : ''
              }`}
            onClick={() => setCurrentPage(page)}
          >
            {page}
          </button>
        ))}
        <button
          className={`px-2 py-1 ${currentPage === totalPages ? 'opacity-40' : 'cursor-pointer'}`}
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          <ChevronRightIcon />
        </button>
      </div>
    </div >
  )
}

export default PaginationFooter;