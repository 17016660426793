// CopyToClipboard.tsx
import React, { FC, useState } from 'react';
import CopyIcon from '../Icons/CopyIcon';

interface CopyToClipboardProps {
  text: string;
  className?: string;
  onCopy?: () => void;
}

const CopyToClipboard: FC<CopyToClipboardProps> = ({ text, className = '', onCopy }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      if (onCopy) onCopy();
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    } catch (err) {
      console.error('Failed to copy!', err);
    }
  };

  return (
    <button
      onClick={handleCopy}
      className={`relative flex items-center justify-center ${className}`}
      aria-label="Copy to clipboard"
    >
      <CopyIcon
        className={
          `transition-transform duration-200 ${copied ?
            'transform scale-110 text-green-500' : ''}`
        }
      />
      {copied && (
        <span className="absolute top-0 left-full ml-2 px-2 py-1 bg-black text-white text-xs rounded">
          Copied!
        </span>
      )}
    </button>
  );
};

export default CopyToClipboard;