import Table from "components/common/Table"

const LineItemsTab = ({ containerHeight }: { containerHeight: number }) => {
  return (
    <div
      className='mt-6'
      style={{ height: `calc(100vh - ${containerHeight + 224}px)` }}
    >
      <Table
        columns={[]}
        currentPage={1}
        data={['', '', '',]}
        height="h-full"
        isError={false}
        isFetching={false}
        rowsPerPage={10}
        setCurrentPage={() => { }}
        setRowsPerPage={() => { }}
        total={13}
      />
    </div>
  )
}

export default LineItemsTab