import { FC, useEffect } from 'react';
import { MsalProvider, useMsal } from '@azure/msal-react';
import publicClientApplication from 'lib/utils/msalInstance';
import FullscreenLoadingIndicator from 'components/common/FullscreenLoadingIndicator';

// A provider that wraps the app, handles authentication
const AuthProvider: FC<{ children: React.ReactNode }> = ({ children }) => {

  // If inProgress is 'none' render the app
  return (
    <MsalProvider instance={publicClientApplication}>
      <MsalInstanceProvider>
        {children}
      </MsalInstanceProvider>
    </MsalProvider >
  );
};

// A provider that initializes the MSAL instance and handles redirect promise
// It automatically redirects the user to the login page if no accounts are found
const MsalInstanceProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { instance: msalInstance, inProgress, accounts } = useMsal();

  useEffect(() => {
    msalInstance.initialize().then(() => {
      if (accounts.length === 0 && inProgress === 'none') {
        msalInstance.handleRedirectPromise();
      }
    });
  }, [inProgress, msalInstance, accounts.length]);

  // If inProgress is anything other than 'none' display a loader to prevent the app from rendering before authentication is complete
  if (inProgress !== 'none') return <FullscreenLoadingIndicator />;

  return <>{children}</>;
};

export default AuthProvider;